import { client } from "@/utils/rest.js";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        currentPatient: null,

    },
    mutations: {
        setCurrentPatient(state, result) {
            state.currentPatient = result
        },
    },
    actions: {
        searchPatient(_, info) {
            return client.post("/patient/find", info)
        },
        addMeasurement(_, info) {
            return client.post("/acc_fields/", info)
        },
        createPatient(_, info) {
            return client.post("/patient/register", info)
        },
        loadFile(_, info) {
            return client.post("/patient/upload_doc", info)
        },
        getAllProcedure() {
            return client.get("/types_of_procedure/")
        },
        createOperations(_, info) {
            return client.post("/procedures_and_operations/create", info)
        },

    }
})