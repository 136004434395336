<template>
  <div class="">
    <router-view name="header" />
    <router-view />
  </div>
</template>
<script>
import "./assets/css/normalize.css";
import "./assets/css/fonts.css";
export default {
  name: "App",
};
</script>
<style lang="scss">
*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  background-color: #e5e5e5;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.container {
  max-width: 1350px;
  padding: 0 15px;
  margin: 0 auto;
}
.title {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 28px;
  }
}

.mx-datepicker {
  .mx-input {
    @media (max-width: 767px) {
      height: 28px;
    }
  }
  & .mx-icon-calendar svg {
    @media (max-width: 767px) {
      width: 0.8em;
    }
  }
}

.mx-datepicker .mx-input:focus,
.mx-datepicker .mx-input:hover {
  border-color: #000;
  /* display: none; */
}
.vs__selected-options .vs__search::placeholder {
  color: #6c757d;
}
.vs__dropdown-toggle {
  @media (max-width: 767px) {
    height: 28px;
  }
}
.v-select .vs__search {
  @media (max-width: 767px) {
    margin-top: 2px;
  }
}
.v-select .vs__selected {
  @media (max-width: 767px) {
    margin-top: 2px;
  }
}
</style>
