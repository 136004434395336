import Vue from "vue";
// import App from "../App.vue"
import VueRouter from "vue-router";
import Header from "../layouts/AppHeader";

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        redirect: "login"
    },
    {
        path: "/login",
        components: {
            header: Header,
            default: () => import("../components/Login")
        },
        name: "Login"
    },
    {
        path: "/search",
        components: {
            header: Header,
            default: () => import("../components/SearchClient")
        },
        name: "Search"
    },
    {
        path: "/patient/create",
        components: {
            header: Header,
            default: () => import("../components/NewClient")
        },
        name: "Create"
    },
    {
        path: "/patient",
        components: {
            header: Header,
            default: () => import("../components/CardClient")
        },
        name: "CardPatient"
    },
    {
        path: "/patient/measurement",
        components: {
            header: Header,
            default: () => import("../components/MeasurementClient")
        },
        name: "Measurement"
    },
    {
        path: "/patient/operations",
        components: {
            header: Header,
            default: () => import("../components/OperationsClient")
        },
        name: "Operations"
    },
    {
        path: '*',
        redirect: "search"

    }
]

const router = new VueRouter({
    mode: "history",
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== "Login" && !localStorage.getItem("access_token")) {
        next({ name: "Login" });
    }
    else if (to.name === "CardPatient" && (from.name !== "Create" && from.name !== "Search" && from.name !== "Measurement" && from.name !== "Operations")) {
        next({ name: "Search" });
    }
    else if (to.name === "Measurement" && from.name !== "CardPatient" && to.name === "Operations" && from.name !== "CardPatient") {
        next({ name: "Search" });
    }
    else {
        next(true);
    }
});
export default router;

