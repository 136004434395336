<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__logo">
        <img src="@/assets/img/logo_2.svg" alt="" />
      </div>
      <div class="header__right" v-if="!isLoginPage">
        <span class="header__right-name" v-if="fio">{{ fio }}</span>
        <button class="header__right-btn" @click="logOut">Вихід</button>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isLoginPage: false,
      fio: null,
    };
  },
  watch: {
    $route(val) {
      if (val && val.path === "/login") {
        this.isLoginPage = true;
      } else {
        this.isLoginPage = false;
        this.fio = localStorage.getItem("doctor");
      }
    },
  },
  methods: {
    logOut() {
      this.$router.push({ name: "Login" });
      localStorage.removeItem("access_token");
      localStorage.removeItem("patient");
      localStorage.doctor = "";
    },
  },
  mounted() {
    this.fio = localStorage.getItem("doctor");
    if (this.$route.path === "/login") {
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  background-color: #c8385d;
  &__wrapper {
    max-width: 1350px;
    padding: 7px 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    width: 90px;
    & img {
      max-width: 100%;
    }
    @media (max-width: 767px) {
      max-width: 70px;
    }
    @media (max-width: 767px) {
      max-width: 60px;
    }
  }
  &__right {
    margin-left: 10px;
    &-name {
      color: #fff;
      font-weight: 700;
      margin-right: 40px;
      @media (max-width: 767px) {
        margin-right: 25px;
      }
    }
    &-btn {
      background: transparent;
      border: 1px solid #ffc107;
      color: #ffc107;
      border-radius: 4px;
      padding: 9px 13px;
      cursor: pointer;
      @media (max-width: 767px) {
        padding: 6px 10px;
      }
    }
  }
}
</style>