import Vue from 'vue'
import App from './App.vue'
import vSelect from "vue-select";
import router from "./router";
import DatePicker from "vue2-datepicker";
import VueUploadComponent from "vue-upload-component";
import client from "./utils/rest";
import store from "./store";
import 'vue2-datepicker/locale/uk';
import VueMask from 'v-mask'

Vue.use(VueMask);
Vue.use(client);


Vue.config.productionTip = false
import "vue-select/src/scss/vue-select.scss";
import 'vue2-datepicker/locale/uk';
import "vue2-datepicker/index.css";

Vue.component("date-picker", DatePicker);
Vue.component("v-select", vSelect);
Vue.component('file-upload', VueUploadComponent)
Vue.component("app-card", () => import("./layouts/AppCard.vue"));
Vue.component("base-input", () => import("./layouts/BaseInput.vue"));
Vue.component("base-textarea", () => import("./layouts/BaseTextarea.vue"));
Vue.component("base-btn", () => import("./layouts/BaseBtn.vue"));
Vue.component("error", () => import("./layouts/Error.vue"))


new Vue({
  router, store,
  render: h => h(App),
}).$mount('#app')


